import React from 'react'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../layouts'
import Feature from '../components/Feature'

const meta = {
  title: 'Contact Maxpower Limited',
  url: 'https://www.maxpowerlimited.com/contact/',
}

const ThanksPage = props => (
  <Layout {...props}>
    <Helmet
      title={meta.title}
      meta={[
        { itemprop: 'name', content: meta.title },
        { property: 'og:title', content: meta.title },
        { property: 'og:url', content: meta.url },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:url', content: meta.url },
      ]}
      link={[{ rel: 'canonical', href: meta.url }]}
    />

    <Feature title={meta.title} />

    <div className="page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-8">
            <h2>Thanks, your enquiry has been sent to our team.</h2>

            <p>
              One of our friendly and helpful team will be in contact with you
              soon.
            </p>
          </div>

          <div className="d-none d-lg-block col-lg-3 offset-lg-1">
            <h3>Contact details</h3>

            <p>
              <strong>Phone:</strong>
              <br />
              020 3633 0956
              <br />
              <strong>Email:</strong>
              <br />
              <span className="email-link">
                <a
                  title="Email Maxpower Limited"
                  href="mailto:info@maxpowerlimited.uk"
                >
                  info@maxpowerlimited.uk
                </a>
              </span>
              <br />
              <strong>Opening hours:</strong>
              <br />
              Monday - Friday - 9am to 5:30pm
            </p>

            <h3>London Office</h3>

            <p>
              71-75 Shelton Street,
              <br />
              Covent Garden,
              <br />
              London,
              <br />
              WC2H 9JQ
            </p>

            <h3>Thetford Office</h3>
            <p>
              Keystone Innovation Centre, <br />
              Croxton road, <br />
              Thetford, <br />
              Norfolk, <br />
              IP24 1JD
            </p>

            <p className="social-icons">
              <a
                href="https://www.facebook.com/Maxpower-Limited-567584623396346/"
                title="Like Maxpower Electrical Compliance on Facebook"
                className="facebook"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-square']} />
              </a>
              <a
                href="https://www.linkedin.com/in/barrie-mcguire-01b10a119/"
                title="Follow Maxpower Electrical Compliance on Linkedin"
                className="linkedin"
              >
                <FontAwesomeIcon icon={['fab', 'linkedin']} />
              </a>
              <a
                href="https://twitter.com/maxpowerlimited"
                title="Follow Maxpower Electrical Compliance on Twitter"
                className="twitter"
              >
                <FontAwesomeIcon icon={['fab', 'twitter-square']} />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThanksPage
